.boxHover:hover {
    border: 3px solid #ee7170 !important;
    /* background-color: red; */
}

.customTable {
    background-color: red;
}

.customerItem {
    background-color: rgba(255,255,255,.2); 
    border-radius: 10px; 
    display: inline; 
    padding: 10px 25px; 
    font-size: 1.2rem; 
    font-weight: 500;
}


.tableField:focus{
    box-shadow: transparent !important;
    border: none !important;
    outline: none!important;
}

.modalContent {
    background-color: white;
    color: black;
    border: none;
    border-radius: 20px;
}

.customFileInput::-webkit-file-upload-button {
    visibility: hidden;
  }
  .customFileInput::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(to top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .customFileInput:hover::before {
    border-color: black;
  }
  .customFileInput:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }


  .visible{
    opacity: 1;
    transform: translateY(0);
  }

.noFocus:focus{
  box-shadow: transparent!important;
  border: none;
  outline: none!important;
}


.accountantsdiv {
    height: 50vh;

    background-repeat: no-repeat;
    background-size: cover;
}

.digitalaccountantsdiv {
  flex: 5;

  background-repeat: no-repeat;
  background-size: cover;
}


.reporterimdiv {
  flex: 5;

  background-repeat: no-repeat;
  background-size: cover;
}

.clissifierimdiv {
  flex: 5;

  background-repeat: no-repeat;
  background-size: cover;
}