.selected{
    color: rgb(238, 113, 112)!important;
}

.routeItem:hover{
    background-color: rgb(255, 255, 255, 0.05)!important;
    transition: 0.1s;
}

.myTab:hover{
    background-color: rgb(255, 255, 255, 0.05);
}