body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(45deg, #120922 0%, #2B1552 100%);
  /* background: white; */
  font-family: 'Poppins', sans-serif;
  color: white;
  transition: all 0.5s;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.modal-content{
  border-radius: 1.5vw;
  background-color: rgb(125, 125, 125, 0.3);
  border: 1px solid rgb(255, 255, 255, 0.2);
  background-repeat: no-repeat;
}

.btn-primary {
  font-weight: 700;
  border-width: 1px;
  --bs-btn-color: #fff;
  --bs-btn-bg: #f25252;
  --bs-btn-border-color: #ee7170;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #eb5b5b;
  --bs-btn-hover-border-color: #eb5b5b;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e95050;
  --bs-btn-active-border-color: #e95050;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ee7170;
  --bs-btn-disabled-border-color: #ee7170;
}


.btn-outline-primary {
  font-weight: 700;
  border-width: 1px;
  --bs-btn-color: #fff;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: #ee7170;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #eb5b5b;
  --bs-btn-hover-border-color: #eb5b5b;
  --bs-btn-focus-shadow-rgb: 49, 131, 253, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e95050;
  --bs-btn-active-border-color: #e95050;
  --bs-btn-active-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: transparent;
}

.btn-outline-secondary {
  font-weight: 700;
  border-width: 3px;
  --bs-btn-color: #fff;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: #4b2976;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4b2976;
  --bs-btn-hover-border-color: #4b2976;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4b2976;
  --bs-btn-active-border-color: #4b2976;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: transparent;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(250, 250, 250, 0.6) !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}


.btn-outline-light{
  --bs-btn-border-color: rgb(255, 255, 255, 0.05);
  --bs-btn-active-border-color: rgb(255, 255, 255, 0.05);
  --bs-btn-hover-bg: rgb(255, 255, 255, 0.1);
}


.Mui-selected{
  color: white !important;
  background-color: #ee7170;
}

.MuiTabs-indicator{
  background-color: white !important;

}

.form-check-input:checked{
  background-color: #ee7170;
  border-color: #ee7170;
}

/* .modal{
  box-shadow: white;
} */

/* input {
  color: white !important
} */



.alert-success {
  --bs-alert-bg: transparent;
}

.alert-danger {
  --bs-alert-bg: transparent;
}


/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.761)!important;
} */

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}


input:-webkit-autofill::first-line {
  margin: 0!important;
  color: black;
  font-family: 'Poppins', sans-serif!important;
  font-weight: 600!important;
}

textarea:focus,
input[type="text"]:focus:not(.MuiInputBase-input),
input[type="file"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select:focus,
.uneditable-input:focus {
  box-shadow: 0 0 0 1.5px rgba(255, 255, 255, 0.761);
  color: white!important;

}

input:-internal-autofill-selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white!important;
}

select:focus{
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.761)!important;
}

fa-magnifying-glass {
  width: 1vw;
  height: 1vw;
}


input[type="text"],
input[type="email"],
input[type="password"]{
  margin: 0;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1vw;
  padding: 1vw;
}

input[type="email"]:disabled{
  margin: 0;
  color: rgb(200, 200, 200)
}

.modal.fade .modal-dialog {-webkit-transform: translate(0,0)scale(.9);transform: translate(0,0)scale(.9);}
.modal.show .modal-dialog {-webkit-transform: translate(0,0)scale(1);transform: translate(0,0)scale(1);}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  /* color: var(--bs-nav-pills-link-active-color); */
  color: rgba(17, 10, 35, 0.95);
  background-color: #ee7170;
}

/* .nav-link:focus, .nav-link:hover {
  color: gray;
} */

.nav-link {
  color: white
}

/* .rbt-input-main.form-control.rbt-input {
  color: black;
} */



.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: rgba(255, 255, 255, 0.4);
  --bs-nav-link-hover-color: rgba(255, 255, 255, 0.4);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* tbody, td, tfoot, th, thead, tr {
  border-color: #4b2976;
} */


tbody, td, tfoot, th, thead, tr {
  border-style: none;
}

.btn-close{
  margin-top: 7px!important;
  color: white;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")!important;
  /* width: 0.3em;
  height: 0.3em; */

}




.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ position:relative}
.files:after {  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input{ background-color:#f1f1f1;}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #ee7170;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}


.clientABNinput:focus {
  border: 1px solid #ee7170;
}


.forgotPassword:hover{
  color: #ee7170;
}



.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}


.whiteContentModal {
  background-color: rgb(32, 30, 34, 0.7);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border: 1px solid rgb(255, 255, 255, 0.2);
  border-radius: 2.5rem;
  width: 25rem;
  margin-left: auto;
  margin-right: auto;
}

.mainContainer::-webkit-scrollbar {
  display: none;
}


.mainContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.btn-close {
  --bs-btn-close-focus-shadow: none;
}

.rbt-input-main.form-control.rbt-input{
  background-color: transparent;
  border: none;
  color: white;
  padding-left: 1vw!important;
  padding-top: 1vw!important;
  padding-bottom: 1vw!important;
  font-size: 1vw;
}

.rbt-input-main.form-control.rbt-input:focus{
  color: white
}

.rbt-input-hint{
  color: white
}

.rbt-input-multi.form-control.rbt-input.focus {
  box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.761)!important;
}

.close.btn-close.rbt-close.rbt-token-remove-button{
  margin: 0!important;
  padding: 0!important;
}

.rbt-token.rbt-token-removeable{
  align-items: center;
  margin-right: 12px;
  padding-right: 5px;
  border-radius: 30px;
  padding: 5px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  background-color: transparent;
}

.rbt-token.rbt-token-removeable.rbt-token-active{
  border: 1px solid white;
  background-color: rgb(255, 255, 255, 0.1)!important;
  color: white;
}

.rbt-input-multi.form-control.rbt-input{
  color: white ;
  padding-top: 5px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.2)!important;
  border-radius: 1vw!important;
  font-size: 0.8vw;
  color: white;
  /* min-height: 150px; */
  border: none;
  /* width: '900px'!important; */
}

div#custom-selections-example {
  max-height: 120px!important;
}

.modal-wider {
  width: 50% !important;
  max-width: none !important;
}

.custom-dropdown-toggle::after {
  display: none;
}

.rbt-input-wrapper{
  align-items: center;
  margin-left: 3px!important;
  font-size: 0.8vw!important;
}

.rbt-input-main{
  margin: 0!important;
  color: white;
  padding: 5px!important;
}

.rbt-input-wrapper > div {
  margin-top: 10px;
  margin-bottom: 10px;
}


.nav-pills .nav-link{
  background-color: transparent!important;
  border: 2px solid #ee7170!important;
}

.nav-pills .nav-link.active{
  background-color: #ee7170!important;
  border: 2px solid #ee7170!important;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: #ee7170!important;

}

.typeahead-input {
  width: 25vw;
  margin: 0 auto;
  background-color: white;
}

.typeahead-input input {
  width: 100%; /* Ensure the input takes the full width of its container */
  color: black;
}

/* .dropdown-item.active, .dropdown-item:active {
  background-color: #ee7170!important;
} */

#customer-search > div > div > input{
  box-shadow: none!important;
  color: white;
  padding-left: 7%!important;
  padding-right: 7%;
  border: 2px solid #863d3d;
  border-radius: 50px;

}

#customer-search > div > div > input::placeholder{
  color:rgb(180, 180, 180)!important;
}


nav.scrolled {
  background-color: rgba(17,10,35,255) !important;
  border-bottom: 1px solid rgb(255, 255, 255, 0.1);
  transition: background-color 400ms linear;
}

.updradeoptionsheaderdiv {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}


.setupheaderdiv {
  height: 35vh;
  background-image: linear-gradient(320deg,rgba(17,10,34,.38) 0,#110a22d4 100%),
  url('./resources/setUpBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.createrequestheaderdiv {
  height: 30vh;
  background-image: linear-gradient(320deg,rgba(17,10,34,.38) 0,#110a22d4 100%),
  url('./resources/createRequest.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.viewreportsheaderdiv {
  height: 30vh;
  background-image: linear-gradient(320deg,rgba(17,10,34,.38) 0,#110a22d4 100%),
  url('./resources/viewReports.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.viewrequestsheaderdiv {
  height: 30vh;
  background-image: linear-gradient(320deg,rgba(17,10,34,.38) 0,#110a22d4 100%),
  url('./resources/viewRequest.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/* .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
  display: none!important;
} */

fieldset > legend {
  display: none;
}

input[type='checkbox']{
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgb(255, 255, 255, 0.2);
}

.MuiInputBase-input{
  height: 20px!important;
}

.MuiOutlinedInput-notchedOutline{
  border: none!important;
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgb(255, 255, 255, 0)!important;
  border-radius: 4px!important;
  padding-left: 20px!important;
}

.MuiPickersLayout-contentWrapper{
  border-radius: 20px!important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
  display: none!important;
}


.modal-fullscreen{
  width: 100%;
}

/* thead {
  position: sticky;
  top:0;
} */

.table {
  --bs-table-hover-color: rgba(255, 255, 255, 0.4);
}

.table>:not(caption)>*>* {
  background-color: transparent;
}

.table-dark {
  --bs-table-hover-bg: rgb(255, 255, 255, 0.05)
}

table  th:nth-child(1){

  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 1vw 0px 0px 0px;

  /* Firefox 1-3.6 */
  -moz-border-radius: 1vw 0px 0px 0px;

  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 1vw 0px 0px 0px;
  }

table  th:nth-last-child(1){
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 0px 1vw 0px 0px;

  /* Firefox 1-3.6 */
  -moz-border-radius: 0px 1vw 0px 0px;

  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 0px 1vw 0px 0px;
}



.accordion-item{
  border: none!important;
}

.accordion{
  --bs-accordion-border-width: none!important;
  --bs-accordion-btn-focus-box-shadow: none!important;
}

.accordion-button{
  padding-left: 0;
  padding-right: 0;
}


thead{
  color: rgb(230, 230, 230);
  border-bottom: 1px solid rgb(255, 255, 255, 0.1);
  background-color: rgb(255, 255, 255, 0.05)
}

tbody {
  padding: 0 1.5vw
}

tr {
  border-top: 1px solid rgb(255, 255, 255, .03)!important
}

.bg-body-tertiary {
  background-color: transparent!important;
}




.step {
  display: flex;
  align-items: center;
  margin-bottom: 100px; /* Increase margin to account for circle size */
  position: relative;
  padding-left: 100px; /* Make space for the absolute positioned circle */
}

.step-circle {
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d4d4d4;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}



.connector {
  position: absolute;
  left: 2vw; /* Center it with respect to the circle */
  top: 50%;   /* Start from the center of the circle */
  width: 1px;
  height: calc(100% + 6vw); /* Extend to the center of the next circle */
  background-color: #d4d4d4;
  z-index: -1; /* Place it behind the circles */
}


.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root{
  height: 2vw;
  width: 3vw;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active{
  height: 3vw;
  width: 3vw;
}

.MuiStepContent-root{
  margin-left: 1.5vw!important;
}

.MuiStepConnector-root.MuiStepConnector-vertical{
  margin-left: 1.5vw;
  min-height: 5vw;
}

span.MuiStepConnector-line.MuiStepConnector-lineVertical{
  min-height: 5vw;
}

span.MuiStepLabel-label{
  color: rgb(255, 255, 255, 0.8)!important;
  font-size: 1vw;
}

p.MuiTypography-root.MuiTypography-body1{
  font-size: 1.2vw;
}

div.MuiBox-root{
  width: 35vw;
}


.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed {
  color: rgb(254, 110, 127);

}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active {
  color: rgb(255, 81, 101);

}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root{
  color: rgb(255, 255, 255, 0.2);

}


.step-1 {
  display: flex;
  align-items: center;
  margin-bottom: 180px; /* Increase margin to account for circle size */
  position: relative;
  padding-left: 100px; /* Make space for the absolute positioned circle */
}


.step-circle-1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d4d4d4;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}



.connector-1 {
  position: absolute;
  left: 25px; /* Center it with respect to the circle */
  top: 50%;   /* Start from the center of the circle */
  width: 1px;
  height: calc(100% + 180px); /* Extend to the center of the next circle */
  background-color: #d4d4d4;
  z-index: -1; /* Place it behind the circles */
}


.layoutContainer > div{
  height: calc(100vh - 19vh);
}


.form-control {
  font-size: 0.9vw;
}

a:hover {text-decoration: underline; }

.accordion-button:not(.collapsed) {
  background-color: rgb(255, 255, 255, 0.1);
  color: rgb(255, 255, 255, 0.9);
  border: 0;
  padding-left: 1vw;
  padding-right: 1vw;
  
}

.accordion {
  --bs-accordion-color: rgb(255, 255, 255, 0.9);
  --bs-accordion-bg: rgb(255, 255, 255, 0.02)!important;

}

.accordion-button {
  font-size: 0.8vw;
  margin: 0;
  background-color: rgb(255, 255, 255, 0.07);
  padding: 0.75vw;
  padding-left: 1vw;
  padding-right: 1vw;
  color: rgb(255, 255, 255, 0.9);
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm{
  background-color: rgba(0, 0, 0, 1); 
}


.MuiDateRangeCalendar-root > div:first-child{
  display: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper{
  background-color: rgb(10, 10, 10, 1)!important;
}

div.popover {
  --bs-popover-bg: rgb(40, 40, 40, 0.3);
  --bs-popover-border-color: rgb(255,255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(50px);
  border-radius: 1vw;
}

.popover-body {
  color: rgb(220, 220, 220)!important;
}

.popover-header {
  background-color: rgb(255, 255, 255, 0.05);
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}

.gradient-3d{  
  background: -webkit-linear-gradient(#C7D3DC,#5B798E);  
  background: -moz-linear-gradient(#C7D3DC,#5B798E);  
  background: -o-linear-gradient(#C7D3DC,#5B798E);  
  background: linear-gradient(#C7D3DC,#5B798E);  

  -webkit-transition: background 1s ease-out;  
  -moz-transition: background 1s ease-out;  
  -o-transition: background 1s ease-out;  
  transition: background 1s ease-out;  
  cursor:pointer;  
}  

.modal-90w {
  max-width: 100vw;
  margin: 3vw;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  
}

.modal-70w {
  max-width: 100vw;
  margin-left: 20vw;
  margin-right: 20vw;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px)
  
}

.modal-50w {
  max-width: 100vw;
  margin-left: 35vw;
  margin-right: 35vw;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px)
  
}



.modal-30w {
  max-width: 100vw;
  margin-left: 36vw;
  margin-right: 36vw;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px)
  
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root{
  background-color: rgb(0, 0, 0, 0.3);
  color: rgb(255, 255, 255, 0.9);
  border-radius: 0.7vw;
}

.MuiTableCell-root.MuiTableCell-sizeMedium{
  color: rgb(255,255, 255, 0.8)
}

/* .css-1086bdv-MuiPaper-root-MuiAccordion-root {{

} */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root {
  margin-bottom: 1vw;
}

.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters{
  height: 2vw;
  margin: 0,
}

input {
  border: 0;
  box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
}


* {
  --scrollbar-color: #00000000;
}

*:hover, *:focus, *:focus-within {
  --scrollbar-color: #00000040 !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #00000000;
  border: 5px solid transparent;
  border-radius: 24px;
  box-shadow: 4px 0px 0px 4px var(--scrollbar-color) inset;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color);
  border: 0px solid transparent;
  box-shadow: none;
}

.MuiGrid-item{
  /* padding: 1vw!important; */
}

.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters{
  padding: 0.5vw;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
  border-radius: 10vw;
  background-color: rgb(0, 0, 0, 0.5);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root{
  min-width: 1vw;
}

input[type="checkbox"] {
  width: 1vw;
  height: 1vw;
  border-radius: 0.1vw;
}

.form-check-input[type=checkbox] {
  border-radius: 0.3vw;
}

MuiOutlinedInput-notchedOutline{
  border-radius: 1vw!important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper{
  background-color: rgb(50, 50, 50, 1);
  border-radius: 1vw;
  padding: 0.5vw;
  color: rgb(255, 255, 255, 0.8);
  /* backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(50px); */
  width: '100%'
}

fieldset.MuiOutlinedInput-notchedOutline{
  background-color: rgb(0, 0, 0, 0.2);
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
  color: rgb(255, 255, 255, 0.8)
}


.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined{
  color: rgb(255, 255, 255, 0.6)
}

.MuiSelect-selec.MuiSelect-outline.MuiInputBase-input.MuiOutlinedInput-input{
  border-radius: 1vw;
}


.MuiOutlinedInput-notchedOutline{
  border-radius: 1vw;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconFilledColorDefault{
  font-size: 1vw;
}

.MuiChip-label.MuiChip-labelMedium.css-6od3lo-MuiChip-label{
  font-size: 0.8vw;
  line-height: 1vw;
  padding: 0 1vw 0 1vw;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault{
  height: 2vw;
}

.MuiChip-label.MuiChip-labelSmall{
  font-size: 0.7vw;
  padding: 0.5vw;
}

.MuiChip-root.MuiChip-outlined.MuiChip-sizeSmall.MuiChip-colorDefault.MuiChip-outlinedDefault{
  height: 1.6vw;
  margin-left: 0.5vw;
  background-color: rgb(255, 255, 255, 0.07);
}
